import React from 'react';
import LayoutMenuBoard from '../../../layouts/layout-menuboard';
import spicy from '../../../images/chili.svg';

const MEATS = [
	'pulled pork',
	'pulled chicken',
	'¼ chicken',
	<>
		homemade sausage{' '}
		<span className="menuboard-meats-subtitle">(+$1.50/serving)</span>{' '}
		<img className="spicy" src={spicy} />
	</>,
	<>
		beef tri-tip{' '}
		<span className="menuboard-meats-subtitle">(+$2/serving)</span>
	</>,
	<>
		beef brisket{' '}
		<span className="menuboard-meats-subtitle">(+$2/serving)</span>
	</>,
	<>
		beef ribs{' '}
		<span className="menuboard-meats-subtitle">(½ or full slab only)</span>
	</>,
	<>
		pork spare ribs{' '}
		<span className="menuboard-meats-subtitle">(+$3/serving)</span>
	</>
];

const SIDES = [
	'smoked corn',
	'mac & cheese',
	'garlic mashed potatoes',
	'bbq beans',
	'southern coleslaw',
	'macaroni salad',
	'potato salad',
	'pasta salad',
	'housemade chips',
	'three bean salad'
];

export default function MenuBoard1() {
	return (
		<LayoutMenuBoard>
			<div style={{ textAlign: 'center' }}>
				<div className="menuboard-section handdrawn-border border-pink menuboard-choose">
					<ol className="menuboard-choose">
						<li>Choose your meats</li>
						<li>Pick two sides</li>
					</ol>
				</div>

				<div className="menuboard-section">
					<h2 className="menuboard-section-title">smoked meats</h2>
					<ul className="menuboard-meats">
						{MEATS.map((m, index) => (
							<li key={index}>{m}</li>
						))}
					</ul>
				</div>

				<div className="menuboard-section">
					<h2 className="menuboard-section-title">sides</h2>

					<ul className="menuboard-meats">
						{SIDES.map((s, index) => (
							<li key={index}>{s}</li>
						))}
					</ul>
				</div>
			</div>
		</LayoutMenuBoard>
	);
}
